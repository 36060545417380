// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advisors-vesting-js": () => import("./../../../src/pages/advisors-vesting.js" /* webpackChunkName: "component---src-pages-advisors-vesting-js" */),
  "component---src-pages-agv-busd-pool-js": () => import("./../../../src/pages/agv-busd-pool.js" /* webpackChunkName: "component---src-pages-agv-busd-pool-js" */),
  "component---src-pages-airdrop-js": () => import("./../../../src/pages/airdrop.js" /* webpackChunkName: "component---src-pages-airdrop-js" */),
  "component---src-pages-cheesus-bsc-pool-js": () => import("./../../../src/pages/cheesus-bsc-pool.js" /* webpackChunkName: "component---src-pages-cheesus-bsc-pool-js" */),
  "component---src-pages-crypto-dwellers-private-sale-js": () => import("./../../../src/pages/crypto-dwellers-private-sale.js" /* webpackChunkName: "component---src-pages-crypto-dwellers-private-sale-js" */),
  "component---src-pages-crypto-league-gaming-private-sale-js": () => import("./../../../src/pages/crypto-league-gaming-private-sale.js" /* webpackChunkName: "component---src-pages-crypto-league-gaming-private-sale-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-marketing-vesting-js": () => import("./../../../src/pages/marketing-vesting.js" /* webpackChunkName: "component---src-pages-marketing-vesting-js" */),
  "component---src-pages-nomaland-bsc-pool-c-js": () => import("./../../../src/pages/nomaland-bsc-pool-c.js" /* webpackChunkName: "component---src-pages-nomaland-bsc-pool-c-js" */),
  "component---src-pages-paycer-bsc-pool-js": () => import("./../../../src/pages/paycer-bsc-pool.js" /* webpackChunkName: "component---src-pages-paycer-bsc-pool-js" */),
  "component---src-pages-plg-arbi-pool-js": () => import("./../../../src/pages/plg-arbi-pool.js" /* webpackChunkName: "component---src-pages-plg-arbi-pool-js" */),
  "component---src-pages-plg-bsc-pool-js": () => import("./../../../src/pages/plg-bsc-pool.js" /* webpackChunkName: "component---src-pages-plg-bsc-pool-js" */),
  "component---src-pages-plg-private-sale-pool-js": () => import("./../../../src/pages/plg-private-sale-pool.js" /* webpackChunkName: "component---src-pages-plg-private-sale-pool-js" */),
  "component---src-pages-reservation-js": () => import("./../../../src/pages/reservation.js" /* webpackChunkName: "component---src-pages-reservation-js" */),
  "component---src-pages-staking-js": () => import("./../../../src/pages/staking.js" /* webpackChunkName: "component---src-pages-staking-js" */),
  "component---src-pages-team-vesting-js": () => import("./../../../src/pages/team-vesting.js" /* webpackChunkName: "component---src-pages-team-vesting-js" */)
}

