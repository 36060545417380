module.exports = {
  //-- SITE SETTINGS -----
  author: "@konstantinmuenster",
  siteTitle: "Plunge",
  siteShortTitle: "", // Used as logo text in header, footer, and splash screen
  siteDescription:
    "ksksks ksksk",
  siteUrl: "https://plungepad.io/",
  siteLanguage: "en_US",
  siteIcon: "content/icon.png", // Relative to gatsby-config file
  seoTitleSuffix: "", // SEO title syntax will be e.g. "Imprint - {seoTitleSuffix}"
  useCookieBar: false, // If you use Google Analytics and want to be GDPR-compliant, set it to true
  googleAnalyticsTrackingId: "", // e.g. UA-XXXXXX-X

  // -- THEME SETTINGS -----
  colors: {
    lightTheme: {
      estern: "#1DAFB6",
      esternDark: "#1A8E94",
      esternLight: "#3EC0C7",
      zodiac: "#0B2540",
      zodiacDark: "#07192C",
      zodiacLight: "#183D63",
      dodger: "#22B5F3",
      dodgerDark: "#23A1D8",
      dodgerLight: "#4DC0F1",
      solitude: "#EBF4FE",
      solitudeDark: "#E5EFF9",
      solitudeLight: "#F3F9FF",
      crayola: "#2A7CF8",
      crayolaDark: "#236FE2",
      crayolaLight: "#438BFA",
      pink: "#9F7AEA",
      pinkDark: "#8C66DA",
      pinkLight: "#B999FA",
      primary: "#9DB9D6",
      tertiary: "#6485A8",
      secondary: "#16385A",
      background: "#EBF2FA",
      gradient: "linear-gradient(147.11deg, #16385A 19.64%, #07192C 114.02%)",
      boxShadow: "0px 3px 10px rgba(7, 23, 40, 0.39)",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
    darkTheme: {
      estern: "#1DAFB6",
      esternDark: "#1A8E94",
      esternLight: "#3EC0C7",
      zodiac: "#0B2540",
      zodiacDark: "#07192C",
      zodiacLight: "#183D63",
      dodger: "#22B5F3",
      dodgerDark: "#23A1D8",
      dodgerLight: "#4DC0F1",
      solitude: "#EBF4FE",
      solitudeDark: "#E5EFF9",
      solitudeLight: "#F3F9FF",
      crayola: "#2A7CF8",
      crayolaDark: "#236FE2",
      crayolaLight: "#438BFA",
      pink: "#9F7AEA",
      pinkDark: "#8C66DA",
      pinkLight: "#B999FA",
      primary: "#9DB9D6",
      tertiary: "#6485A8",
      secondary: "#16385A",
      background: "#EBF2FA",
      gradient: "linear-gradient(147.11deg, #16385A 19.64%, #07192C 114.02%)",
      boxShadow: "0px 3px 10px rgba(7, 23, 40, 0.39)",
      text: "rgba(255, 255, 255, 0.87)",
      subtext: "#AAAAAA",
      card: "#1C1C1C",
      scrollBar: "rgba(255, 255, 255, 0.5)",
      boxShadowHover: "rgba(0, 0, 0, 0.32)",
    },
  },
  fonts: {
    primary: "montserrat, Roboto, Arial, sans-serif",
    poppins: "Poppins, Roboto, Arial, sans-serif",
    inter: "inter, Roboto, Arial, sans-serif",
    kumbh: "kumbh, Roboto, Arial, sans-serif"
  },

  //-- ARTICLES SECTION SETTINGS -----
  // You can create your own Medium feed with this rss to json converter: https://rss2json.com/
  // To access your Medium RSS feed, just replace this url with your username: https://medium.com/feed/@{yourname}
  mediumRssFeed:
    "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fmedium.com%2Ffeed%2F%40konstantinmuenster",
  // rssFeed: "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.theguardian.com%2Finternational%2Frss",

  shownArticles: 3,

  //-- SOCIAL MEDIA SETTINGS -----
  // There are icons available for the following platforms:
  // Medium, GitHub, LinkedIn, XING, Behance, E-Mail
  socialMedia: [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/konstantin-muenster/",
    },
    {
      name: "Medium",
      url: "https://konstantinmuenster.medium.com/",
    },
    {
      name: "Github",
      url:
        "https://github.com/konstantinmuenster/gatsby-starter-portfolio-minimal",
    },
    {
      name: "Behance",
      url: "https://www.behance.net/konstanmnster",
    },
    // {
    //   name: "Mail",
    //   url: "mailto:mail@konstantin.digital",
    // },
  ],

  //-- NAVIGATION SETTINGS -----
  navLinks: {
    menu: [
      {
        name: "About Me",
        url: "/#about",
      },
      {
        name: "Features",
        url: "/#projects",
      },
      {
        name: "Contact",
        url: "/#contact",
      },
    ],
    button: {
      useFileName: true,
      name: "Resume",
      fileName: "goldenpuppy.pdf", // the file has to be placed inside the static folder at the root level
      url: "", // if useFileName=false, you can set an anchor link here and use the button for navigational purposes
    },
  },
  footerLinks: [
    {
      name: "Privacy",
      url: "/privacy",
    },
    {
      name: "Imprint",
      url: "/imprint",
    },
  ],
}
