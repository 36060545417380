import  * as actionTypes from 'store/actions/types'
import { updateObject } from 'utils';

const initialState = {
    launchpads: {

    }
}

const getLaunchpadsListRequest = (state, action) => {
    return updateObject( state, {
        launchpads: { r: true, payload: state.launchpads }
    });
}

const getLaunchpadsListSuccess = (state, action) => {
    return updateObject( state, {
        launchpads: { s: true, payload: action.launchpads }
    });
}

const getLaunchpadsListFail = (state, action) => {
    return updateObject( state, {
        launchpads: { f: true }
    });
}
const launchpadReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.GET_LAUNCHPADS_LIST_REQUEST: return getLaunchpadsListRequest( state, action );
        case actionTypes.GET_LAUNCHPADS_LIST_SUCCESS: return getLaunchpadsListSuccess( state, action );
        case actionTypes.GET_LAUNCHPADS_LIST_FAIL: return getLaunchpadsListFail( state, action );
        default: return state;
    }
};

export default launchpadReducer;